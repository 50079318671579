import React from 'react';
import { IonList, IonItem, IonLabel } from '@ionic/react';

interface AboutPopoverProps {
  dismiss: () => void;
};

const AboutPopover: React.FC<AboutPopoverProps> = ({dismiss}) => {

  const close = (url: string) => {
    window.open(url, '_blank');
    dismiss();
  };

  return (
    <IonList>
      <IonItem button onClick={() => close('https://www.avvo.com/attorneys/22201-va-christopher-davis-4131196.html')}>
        <IonLabel>Avvo Profile</IonLabel>
      </IonItem>
      <IonItem button onClick={() => close('http://www.linkedin.com/in/chris-davis-4562991b')}>
        <IonLabel>LinkedIn Profile</IonLabel>
      </IonItem>
    </IonList >
  )
}

export default AboutPopover;
