import React from 'react';
import { useHistory } from "react-router-dom";
import { isPlatform } from '@ionic/react'
import { IonHeader, IonToolbar, IonContent, IonPage, IonMenuButton, IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonSlide, IonSlides } from '@ionic/react';
import { IonCard, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { IonChip } from '@ionic/react';
import { IonFab, IonFabButton } from '@ionic/react';
import { call, chatbubblesOutline, mail, navigateCircleOutline } from 'ionicons/icons';

import './Home.scss';

interface HomeProps { }

const Home: React.FC<HomeProps> = () => {

  const isTablet = isPlatform('tablet') || isPlatform('desktop');
  const areasOfPractice = ['Criminal', 'Traffic', 'DUI', 'Marijuana'];
  const moreAreasOfPractice = [
    'DUI/DWI Defense',
    'Drug Crime Defense',
    'Domestic Violence Defense',
    'Violent Crime Defense',
    'Theft Crime Defense',
    'Juvenile Crime Defense',
    'Traffic Violations',
    'Assault and Battery',
    'Expungements',
    'Probation Violations'
  ]
  const servicingArea = {
    left: [
      'City of Alexandia',
      'Arlington County',
      'City of Fairfax',
      'Fairfax County',
      'City of Falls Church',
      'Loudoun County'
    ],
    right: [
      'Prince William County',
      'Fauquier County',
      'Spotsylvania County',
      'Stafford County',
      'City of Fredericksburg',
      'Culpeper County'
    ]
  };
  let history = useHistory();

  function handleClick() {
    history.push('/message');
  }

  let slideOpts = {
    zoom: isTablet ? false : true,
    slidesPerView: isTablet ? 3.5 : 1.5,
    spaceBetween: isTablet ? 5 : 10,
    centeredSlides: false,
  };

  return (
    <IonPage id="home-page">
      <IonContent>

        <IonFab vertical="top" horizontal="end" slot="fixed">
          <IonFabButton color="danger">
            <IonIcon icon={call} />
          </IonFabButton>
        </IonFab>

        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="home-header">
          {/* Instead of loading an image each time the select changes, use opacity to transition them */}
          <div className="home-image wilson" style={{'opacity': '1' }}></div>
        </div>

        <div className="home-info">

          <p className="ion-padding-start job-prefix">Law Offices of</p>
          <h3 className="ion-padding-top0 ion-padding-start">Christopher Michael Davis, PLC</h3>
          <p className="ion-padding-start job-title">Criminal Lawyer</p>

          <hr className="stars" />

          <p className="ion-padding-start ion-padding-end">
            Based in Arlington County, The Law Offices of Christopher Michael Davis, PLC provides legal services throughout the Commonwealth of Virginia. Focusing on Criminal Defense, Chris Davis is ready to help you address any situation. From a traffic violation to a jailable criminal offense, if you have been arrested or are the subject of a criminal investigation, please contact us.
          </p>

          <p className="ion-padding-start ion-padding-end">
            A no-obligation criminal law consultation is available by appointment. Criminal charges need to be addressed as early as possible in the legal process to help you avoid jail or a criminal record. Do not hesitate to seek legal advice so that a timely analysis and investigation can be undertaken to help preserve all available evidence and defenses. If you are of limited means, but are not eligible for court-appointed indigent defense, flexible payment arrangements may be available. Please contact Chris Davis to set up a consultation.
          </p>

          <h3 className="ion-padding-top ion-padding-start">Areas of Practice</h3>
          <IonSlides className="ion-padding-start ion-padding-end home-slides" options={slideOpts}>
            {areasOfPractice.map(area => (
              <IonSlide key={area}>
                <IonCard className="home-card">
                  <img alt={area} src={`/assets/img/home/areas-${area.toLowerCase()}.jpg`} />
                  <IonCardHeader>
                    <IonCardSubtitle>{area}</IonCardSubtitle>
                  </IonCardHeader>
                </IonCard>
              </IonSlide>
            ))}
          </IonSlides>

          <p className="ion-padding-start ion-padding-end">
            {moreAreasOfPractice.map(area => (
              <IonChip key={area}>
                <IonLabel>{area}</IonLabel>
              </IonChip>
            ))}
          </p>

          <h3 className="ion-padding-top ion-padding-start">
            <span color="default">FREE!</span> Consultations &nbsp;
            <IonIcon icon={chatbubblesOutline} />
          </h3>
          <p className="ion-padding-start ion-padding-end">
            Please call, or e-mail, to arrange for a legal consultation. In-person meetings are by appointment only. <b>Virtual consultations are available.</b> Available times and locations are very flexible to meet your needs. Our office is conveniently located directly across the street from the <i>Court House Metro Station</i> and a short walk from <i>Arlington Court House</i>.
          </p>

          <p className="ion-padding-start ion-padding-end ion-text-center">
            <IonButton color="success" shape="round">
              <IonIcon slot="start" icon={call} />
              Call Now
            </IonButton>
             or 
            <IonButton color="tertiary" shape="round" onClick={handleClick}>
              <IonIcon slot="start" icon={mail} />
              Message Us
            </IonButton>
          </p>

          <h3 className="ion-padding-top ion-padding-start">Servicing Areas</h3>
          <p className="">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-sm="6" className="servicing-col">
                  {servicingArea.left.map(area => (
                    <IonItem key={area}>
                      <IonIcon className="servicing" icon={navigateCircleOutline} slot="start" />
                      <IonLabel>
                        {area}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonCol>
                <IonCol size="12" size-sm="6" className="servicing-col">
                  {servicingArea.right.map(area => (
                    <IonItem key={area}>
                      <IonIcon className="servicing" icon={navigateCircleOutline} slot="start" />
                      <IonLabel>
                        {area}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonCol>
              </IonRow>
            </IonGrid>
          </p>

        </div>
      </IonContent>

    </IonPage>
  );
};

export default React.memo(Home);
