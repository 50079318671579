import React, { useState, useRef, useEffect } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonSlides, IonSlide, IonIcon, useIonViewWillEnter } from '@ionic/react';
import { IonInput, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';
import { IonToast } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { arrowForward, arrowBack } from 'ionicons/icons';
import { setMenuEnabled, sendMessage } from '../data/sessions/sessions.actions';
// TODO: Set Dark Mode like this...
// import { setHasSeenTutorial } from '../data/user/user.actions';
import './Message.scss';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';

interface OwnProps extends RouteComponentProps {};

interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled;
  sendMessage: typeof sendMessage;
}
interface StateProps {
  loading: boolean,
  messageStatus: any;
}

interface MessageProps extends OwnProps, StateProps, DispatchProps { };

const Message: React.FC<MessageProps> = ({ history, setMenuEnabled, sendMessage, loading, messageStatus }) => {
  const [formData, setFormData] = useState();
  const methods = useForm();
  const { register, handleSubmit, errors, trigger } = methods;
  const [showSkip, setShowSkip] = useState(true);
  const [firstName, setFirstName] = useState('N/A');
  const [pronoun, setPronoun] = useState(null);
  const [courthouse, setCourthouse] = useState(null);
  const [availableDays, setDays] = useState([]);
  const [availableTimes, setTimes] = useState([]);
  const [message, setMessage] = useState('');
  const [showRetry, setShowRetry] = useState(false);
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  let buttonIndex = 0;

  useEffect(() => {
    slideRef.current!.lockSwipes(true);
  });

  useEffect(() => {
    if (messageStatus.message) {
      slideRef.current!.slidePrev();
    }
    if (messageStatus.error) {
      setShowRetry(true);
    }
  }, [messageStatus]);

  const availablity = [
    { time: 'Morning', hours: '7AM-12PM', isChecked: true },
    { time: 'Afternoon', hours: '12PM-5PM', isChecked: true },
    { time: 'Evening', hours: '5PM-9PM', isChecked: false },
    { time: 'Night', hours: '9PM-7AM', isChecked: false },
  ];
  const days = [
    { day: 'Sunday', abbr: 'Sun', isChecked: false },
    { day: 'Monday', abbr: 'Mon', isChecked: true },
    { day: 'Tuesday', abbr: 'Tue', isChecked: true },
    { day: 'Wednesday', abbr: 'Wed', isChecked: true },
    { day: 'Thursday', abbr: 'Thu', isChecked: true },
    { day: 'Friday', abbr: 'Fri', isChecked: true },
    { day: 'Saturday', abbr: 'Sat', isChecked: false },
  ]

  useIonViewWillEnter(() => {
    setMenuEnabled(false);
  });

  const startApp = async () => {
    setShowRetry(false);
    await slideRef.current!.slideTo(1);
    await setMenuEnabled(true);
    history.push('/tabs/home', { direction: 'none' });
  };

  const handleSlideChangeStart = () => {
    slideRef.current!.isEnd().then(isEnd => setShowSkip(!isEnd));
  };

  const lockSwipe = async (locked: boolean) => slideRef.current!.lockSwipes(locked);

  const next = (fields: any = []) => async () => {
    if (fields.length > 0) {
      const results = await trigger(fields);
      if (!results) return;
    }
    await lockSwipe(false);
    await slideRef.current!.slideNext();
    await lockSwipe(true);
  }

  const back = async () => {
    await lockSwipe(false);
    await slideRef.current!.slidePrev();
    await lockSwipe(true);
  }

  const onSubmit = async (data: any) => {
    console.log(data);
    setFormData(data);
    sendMessage(data);

    await lockSwipe(false);
    await slideRef.current!.slideNext();
    await lockSwipe(true);
  }

  const retry = () => {
    sendMessage(formData);
    setShowRetry(false);
  }

  const renderNextButton = (fields?: any) => (
    <IonButton fill="clear" onClick={next(fields)} key={`key-${(buttonIndex++)}`}>
      Next
      <IonIcon slot="end" icon={arrowForward} />
    </IonButton>
  );

  const renderBackButton = () => (
    <IonButton fill="clear" onClick={back} key={`key-${(buttonIndex++)}`}>
      Back
      <IonIcon slot="start" icon={arrowBack} />
    </IonButton>
  );

  const renderSubmitButton = () => (
    <IonButton fill="clear" type="submit" key={`key-${(buttonIndex++)}`}>
      SUBMIT
      <IonIcon slot="end" icon={arrowForward} />
    </IonButton>
  );

  const renderCloseButton = () => (
    <IonButton fill="clear" onClick={startApp} key={`key-${(buttonIndex++)}`}>
      Close
    </IonButton>
  );

  const renderStartSlide = () => (
    <>
      {/*<img src="assets/img/ica-slidebox-img-1.png" alt="" className="slide-image" />*/}
      <h2 className="slide-title">
        We're Here to Help
      </h2>
      <p>
        We know dealing with the court, and finding the right lawyer to help you, can be a stressfull time for you and daunting task.
        In order to better serve you, we will walk you throught and ask a few simple questions, to help facilitate your FREE consultation.
      </p>
      {renderNextButton()}
    </>
  )

  const renderNameSlide = () => (
    <>
      <h2 className="slide-title">About You</h2>
      <p>
        Let's us allow you to introduce yourself, how you prefer to be called, and how you would like to be indentified.
      </p>
      <p>
        <IonInput name="first" placeholder="Legal First Name" type="text" onIonChange={e => setFirstName(e.detail.value!)} ref={register({ required: true })} color={errors.first ? 'danger' : ''}></IonInput>
        <IonInput name="nickname" placeholder="Preferred First Name" type="text" ref={register}></IonInput>
        <IonInput name="last" placeholder="Legel Last Name" type="text" ref={register({ required: true })} color={errors.last ? 'danger' : ''}></IonInput>
        <IonSelect name="pronoun" value={pronoun} placeholder="Preferred Pronoun" onIonChange={e => setPronoun(e.detail.value)} ref={register}>
          <IonSelectOption value="he">He/Him/His</IonSelectOption>
          <IonSelectOption value="she">She/Her/Hers</IonSelectOption>
          <IonSelectOption value="they">They/Them/Their</IonSelectOption>
          <IonSelectOption value="other">Other (Please Ask)</IonSelectOption>
        </IonSelect>
      </p>
      {renderBackButton()}
      {renderNextButton(['first', 'last'])}
    </>
  );

  const renderContactSlide = () => (
    <>
      <h2 className="slide-title">Hello, {firstName}!</h2>
      <p>
        To best help you and to avoid delays, please provide a phone number we can contact you at, and also an email address any docments may be sent to.
      </p>
      <p>
        <IonInput name="email" placeholder="Email Address" type="email" ref={register({ required: true })} color={errors.email ? 'danger' : ''}></IonInput>
        <IonInput name="mobileNumber" placeholder="Mobile Phone Number" type="tel" ref={register({ required: true })} color={errors.mobileNumber ? 'danger' : ''}></IonInput>
        <IonInput name="otherNumber" placeholder="Other Phone Number" type="tel" ref={register}></IonInput>
      </p>
      {renderBackButton()}
      {renderNextButton(['email', 'mobileNumber'])}
    </>
  );

  const renderAvaliablitySlide = () => (
    <>
      <h2 className="slide-title">Your Availablity?</h2>
      <p>
        Thanks for the contact information, what would be the best days and times to talk over your case.
      </p>
      <p>
        <IonSelect name="availableDays" value={availableDays} placeholder="Days of Availablity" multiple onIonChange={e => setDays(e.detail.value)} ref={register({ required: true })} color={errors.availableDays ? 'danger' : ''}>
          {days.map(({ day, abbr, isChecked }, i) => (
            <IonSelectOption value={abbr}>{day}</IonSelectOption>
          ))}
        </IonSelect>
        <IonSelect name="availableTimes" value={availableTimes} placeholder="Hours of Availablity" multiple onIonChange={e => setTimes(e.detail.value)} ref={register({ required: true })} color={errors.availableTimes ? 'danger' : ''}>
          {availablity.map(({ time, hours, isChecked }, i) => (
            <IonSelectOption value={time}>{time} ({hours})</IonSelectOption>
          ))}
        </IonSelect>
      </p>
      {renderBackButton()}
      {renderNextButton(['availableDays', 'availableTimes'])}
    </>
  );

  const renderCourtSlide = () => (
    <>
      <h2 className="slide-title">Court Information</h2>
      <p>
        To best help you and to avoid delays, please provide a phone number we can contact you at, and also an email address any docments may be sent to.
      </p>
      <p>
        <IonInput name="caseNo" placeholder="Case Number" type="text" ref={register}></IonInput>
        <IonSelect name="courthouse" value={courthouse} placeholder="Which Courthouse" onIonChange={e => setCourthouse(e.detail.value)} ref={register({ required: true })} color={errors.courthouse ? 'danger' : ''}>
          <IonSelectOption value="alex">Alexandria Court</IonSelectOption>
          <IonSelectOption value="arl">Arlington Court</IonSelectOption>
          <IonSelectOption value="fair">Fairfax Court</IonSelectOption>
          <IonSelectOption value="prince">Prince William Court</IonSelectOption>
        </IonSelect>
      </p>
      {renderBackButton()}
      {renderNextButton(['courthouse'])}
    </>
  );

  const renderMessageSlide = () => (
    <>
      <h2 className="slide-title">A Breif Message</h2>
      <p>
        Now that we have all the formalites out of the way, you may now provide a breif overview of your situtation.
      </p>
      <p>
        <IonTextarea name="message" value={message} onIonChange={e => setMessage(e.detail.value!)} ref={register({ required: true })} color={errors.message ? 'danger' : ''}></IonTextarea>
      </p>
      {renderBackButton()}
      {renderNextButton(['message'])}
    </>
  );

  const renderDoubleCheckSlide = () => (
    <>
      <h2 className="slide-title">Double Check</h2>
      <p>
        We'll give you just a moment to go over your information you've provided to be sure everything is correct.
        You may go back and update any information before submitting your request.
      </p>
      <p>
        Preview
      </p>
      {renderBackButton()}
      {renderSubmitButton()}
    </>
  );

  const renderStatus = () => {
    if (messageStatus.error) {
      return (
        <>
          <h2 className="slide-title">Opps!!</h2>
          <p>
            We're sorry {formData && formData.first}, but your message could not be sent at this time.
            You may retrying to submit your message but, if it does not go through, please call us and leave a message.
            We will get back to you as soon as possible.
          </p>
        </>
      )
    }
    return (
      <>
        <h2 className="slide-title">Thanks, {formData && formData.first}!</h2>
        <p>
          Thank you for allowing us to review your case. We will get in contact with your at your earliest convence.
          If you prefer to be contacted quickly, you may always call 703-555-1212. If no one answers, please leave a message, and we will call back ASAP.
        </p>
      </>
    );
  }

  const renderThankYouSlide = () => (
    <>
      {loading && 'Loading...'}
      {!loading && renderStatus()}
      {renderCloseButton()}
    </>
  );

  return (
    <IonPage id="message-page">
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && <IonButton color='primary' onClick={startApp}>Cancel</IonButton>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonSlides ref={slideRef} onIonSlideWillChange={handleSlideChangeStart} pager={true}>
            <IonSlide>
              {renderStartSlide()}
            </IonSlide>
            <IonSlide>
              {renderNameSlide()}
            </IonSlide>
            <IonSlide>
              {renderContactSlide()}
            </IonSlide>
            <IonSlide>
              {renderAvaliablitySlide()}
            </IonSlide>
            <IonSlide>
              {renderCourtSlide()}
            </IonSlide>
            <IonSlide>
              {renderMessageSlide()}
            </IonSlide>
            <IonSlide>
              {renderDoubleCheckSlide()}
            </IonSlide>
            <IonSlide>
              {renderThankYouSlide()}
            </IonSlide>
          </IonSlides>
        </form>
      </IonContent>
      <IonToast
        color="danger"
        isOpen={showRetry}
        message="Try to resend message?"
        duration={0}
        buttons={[
          {
            text: 'Retry',
            role: 'cancel',
            handler: () => retry(),
          }
        ]}
      />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    loading: state.user.loading,
    messageStatus: state.data.messageStatus,
  }),
  mapDispatchToProps: ({
    setMenuEnabled,
    sendMessage
  }),
  component: Message
});
