import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonPopover } from '@ionic/react';
import './About.scss';
import { baseballOutline, basketballOutline, ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import AboutPopover from '../components/AboutPopover';

interface AboutProps { }

const About: React.FC<AboutProps> = () => {

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState();
  const [sinceDate] = useState('2008-01-01T00:00:00-05:00');

  const presentPopover = (e: React.MouseEvent) => {
    setPopoverEvent(e.nativeEvent);
    setShowPopover(true);
  };

  function yearsOfPractice (input: string) {
    const start = new Date(input);
    const end = new Date();
    const years = end.getFullYear() - start.getFullYear();
    return years;
  }

  return (
    <IonPage id="about-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={presentPopover}>
                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="about-header">
          <div className="about-image georgetown" style={{'opacity': '1'}}></div>
        </div>
        <div className="about-info">
          <h3 className="ion-padding-top ion-padding-start">A Few Words About Me</h3>

          <p className="ion-padding-start ion-padding-end">
            Having grown up in Northern Virginia and experienced the mistakes of youth, I understand firsthand the need for a strong advocate when faced with criminal charges. The consequences of one mistake or misunderstanding can be detrimental to your future. You need an attorney that will fight for you every step of the way. I take on every client's situation as if I were in their shoes and will provide as tenacious a defense as possible.
          </p>

          <p className="ion-padding-start ion-padding-end">
            You also need to be fully informed and active in your own defense. Keeping clients informed of all the ways in which a conviction can affect their life is paramount. I strive to make sure my clients understand each and every phase of the legal process and are actively engaged in resolving their case. Communication is essential for building a strong attorney-client relationship, which is the foundation for a successful criminal defense.
          </p>

          <h3 className="ion-padding-top ion-padding-start">Details</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Location
              </IonLabel>
              <IonLabel className="ion-text-end">
                Arlington, VA
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                Years of Practice
              </IonLabel>
              <IonLabel className="ion-text-end">
                { yearsOfPractice(sinceDate) } Years
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                Phone Number
              </IonLabel>
              <IonLabel className="ion-text-end">
                <a href="tel:703-986-6344">(703) 986-6344</a>
              </IonLabel>
            </IonItem>
          </IonList>

          <h3 className="ion-padding-top ion-padding-start">Pets</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Bulldog
              </IonLabel>
              <IonLabel className="ion-text-end">
                Reggie
              </IonLabel>
            </IonItem>
          </IonList>

          <h3 className="ion-padding-top ion-padding-start">Favorite Teams</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                <IonIcon icon={basketballOutline} slot="start" />
                NCAA Basketball
              </IonLabel>
              <IonLabel className="ion-text-end">
                Georgetown Hoyas
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <IonIcon icon={baseballOutline} slot="start" />
                MLB
              </IonLabel>
              <IonLabel className="ion-text-end">
                Baltimore Orioles
              </IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <AboutPopover dismiss={() => setShowPopover(false)} />
      </IonPopover>
    </IonPage>
  );
};

export default React.memo(About);
