import React from 'react';
import { IonItem, IonLabel, IonAvatar, IonList } from '@ionic/react';


interface CredentialItemProps {
  item: any,
}

const CredentialItem: React.FC<CredentialItemProps> = ({ item }) => {
  return (
    <IonList>
      <IonItem button detail={false} lines="none" className="credential-item">
        <IonAvatar slot="start">
          <img src={`/assets/img/credentials/${item.image}`} alt="Credential Logo" />
        </IonAvatar>
        <IonLabel>
          <h2 className="title">{item.title}</h2>
          <h3>{item.subtitle}</h3>
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

export default CredentialItem;
