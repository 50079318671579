import React from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { mailOutline, moonOutline, homeOutline, informationCircleOutline, logoLinkedin, mapOutline, openOutline, ribbonOutline } from 'ionicons/icons';

import { connect } from '../data/connect';
import { setDarkMode } from '../data/user/user.actions';

import './Menu.scss'

const svgs = {
  avvo: '/assets/icon/avvo.svg'
};
const urls = {
  avvo: 'https://www.avvo.com/attorneys/22201-va-christopher-davis-4131196.html',
  linkedin: 'http://www.linkedin.com/in/chris-davis-4562991b'
}

const routes = {
  appPages: [
    { title: 'Home', path: '/tabs/home', icon: homeOutline },
    { title: 'Credentials', path: '/tabs/credentials', icon: ribbonOutline },
    { title: 'Map', path: '/tabs/map', icon: mapOutline },
    { title: 'About', path: '/tabs/about', icon: informationCircleOutline }
  ],
  externalPages: [
    { title: 'Avvo Profile', url: urls.avvo, path: '', src: svgs.avvo },
    { title: 'LinkedIn Profile', url: urls.linkedin, path: '', icon: logoLinkedin }
  ]
};

interface Pages {
  title: string,
  path: string,
  url?: string,
  icon?: string,
  src?: string,
  routerDirection?: string
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, history, isAuthenticated, setDarkMode, menuEnabled }) => {
  const location = useLocation();
  const renderExternalLink = () => <IonIcon icon={openOutline} color="primary" size="small" />

  function renderIcon(p: Pages) {
    if (p.src) {
      return <IonIcon slot="start" src={p.src} />;
    }
    return <IonIcon slot="start" icon={p.icon} />;
  }

  function renderMessageItem() {
    const onClick = () => history.push('/message');
    return (
      <IonItem button onClick={onClick}>
        <IonIcon slot="start" icon={mailOutline} />
        Message Us
      </IonItem>
    );
  }

  function renderDarkModeItem() {
    return (
      <IonItem>
        <IonIcon slot="start" icon={moonOutline}></IonIcon>
        <IonLabel>Dark Mode</IonLabel>
        <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
      </IonItem>
    );
  }

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            {renderIcon(p)}
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  function renderExternalItems(list: Pages[]) {
    return list
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false}>
            {renderIcon(p)}
            <IonLabel>
              <a target="_blank" rel="noopener noreferrer" href={p.url}>{p.title} </a>
              {renderExternalLink()}
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu id="side-menu" type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonItem className="ion-text-center">
            <img className="profile" alt="Profile Pic" src="/assets/img/about/profile-pic-small-edited.jpg" />
          </IonItem>
          <hr className="stars" />
          {renderlistItems(routes.appPages)}
          {renderMessageItem()}
          {renderDarkModeItem()}
        </IonList>
        <IonList lines="none">
          <IonListHeader>External Links</IonListHeader>
          {renderExternalItems(routes.externalPages)}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled
  }),
  mapDispatchToProps: ({
    setDarkMode
  }),
  component: withRouter(Menu)
})
